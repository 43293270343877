import React from 'react';
import './index.css';

const AlertBox = props => {
  return (
    <div className="flex flex-row items-center text-2xl text-black uppercase font-inter-semibold">
      <div className={`${props.bg} w-3 h-3 rounded-full mr-2`} />
      <p>{props.level}
      </p>
    </div>
  );
};

class Alert extends React.Component {
  render() {
    return (
      <>
            {(() => {
              switch (this.props.level) {
                case "very high":
                  return (
                    <AlertBox level={this.props.level} bg="bg-red-600" />
                  );
                case "high":
                  return (
                    <AlertBox level={this.props.level} bg="bg-orange-600" />
                  );
                case "moderate":
                  return (
                    <AlertBox level={this.props.level} bg="bg-yellow-600"/>
                  );
                case "low":
                  return (
                    <AlertBox level={this.props.level} bg="bg-green-600" />
                  );
                case "very low":
                  return (
                    <AlertBox level={this.props.level} bg="bg-green-400" />
                  );
                default:
                  return null;
              }
            })()}
            </>
    );
  }
}

export default Alert;