import React from "react";
import Alert from "./Alert.js";
import { ReactComponent as CheckIcon } from "./check-icon.svg";
import "./index.css";

import Firebase from "firebase";
import config from "./config";

import moment from "moment";

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
  VictoryLegend,
  VictoryContainer
} from "victory";

class App extends React.Component {
  constructor(props) {
    super(props);
    Firebase.initializeApp(config);
    this.state = {
      genMix: [],
      forecast: [],
      showRegionSelect: false,
      selectedRegionId: null,
      regions: [
        { id: 1, name: "North Scotland" },
        { id: 2, name: "South Scotland" },
        { id: 3, name: "North West England" },
        { id: 4, name: "North East England" },
        { id: 5, name: "Yorkshire" },
        { id: 6, name: "North Wales" },
        { id: 7, name: "South Wales" },
        { id: 8, name: "West Midlands" },
        { id: 9, name: "East Midlands" },
        { id: 10, name: "East England" },
        { id: 11, name: "South West England" },
        { id: 12, name: "South England" },
        { id: 13, name: "London" },
        { id: 14, name: "South East England" }
      ]
    };
    this.handleToggleClick = this.handleToggleClick.bind(this);
  }

  handleToggleClick() {
    this.setState(state => ({
      showRegionSelect: !state.showRegionSelect
    }));
  }

  async handleClick(e, id, name) {
    console.log(id)
    localStorage.setItem("selectedRegionId", id);
    localStorage.setItem("selectedRegionName", name);
    await this.setState(state => ({
      selectedRegionId: id,
      selectedRegionName: name,
      showRegionSelect: !state.showRegionSelect
    }));
    await this.getData();
  }

  async componentDidMount() {
    await this.hydrateStateWithLocalStorage();
    if (this.state.selectedRegionId === null) {
       this.setState(state => ({
         selectedRegionId: 13,
         selectedRegionName: "London"
       }));
    }
    await this.getData();
  }

  hydrateStateWithLocalStorage() {
    this.setState({
      forecastGraph: JSON.parse(localStorage.getItem("forecastGraph")),
      forecast: JSON.parse(localStorage.getItem("forecast")),
      genMixGraph: JSON.parse(localStorage.getItem("genMixGraph")),
      nowForecast: localStorage.getItem("nowForecast"),
      nowIndex: localStorage.getItem("nowIndex"),
      when: localStorage.getItem("when"),
      from: localStorage.getItem("from"),
      to: localStorage.getItem("to"),
      selectedRegionId: localStorage.getItem("selectedRegionId"),
      selectedRegionName: localStorage.getItem("selectedRegionName"),
      bestForecast: localStorage.getItem("bestForecast"),
      timeTicks: localStorage.getItem("timeTicks"),
      dataLoaded: true
    });
  }

  getData = () => {
    let ref = Firebase.database().ref("/" + this.state.selectedRegionId);
    ref.on("value", snapshot => {
      const data = snapshot.val();
      console.log(data);
      localStorage.setItem(
        "forecastGraph",
        JSON.stringify(data.forecastGraph.slice(0, 47))
      );
      localStorage.setItem("forecast", JSON.stringify(data.forecast));
      localStorage.setItem("genMixGraph", JSON.stringify(data.genMixGraph));
      localStorage.setItem("nowForecast", data.forecast[0].forecast);
      localStorage.setItem("nowIndex", data.forecast[0].index);
      localStorage.setItem("when", data.bestTime[0].when);
      localStorage.setItem(
        "from",
        moment
          .unix(data.bestTime[0].from)
          .utc()
          .format("HH:mm")
      );
      localStorage.setItem(
        "to",
        moment
          .unix(data.bestTime[0].to)
          .utc()
          .format("HH:mm")
      );
      localStorage.setItem(
        "bestForecast",
        data.bestTime[0].forecast.toFixed(0)
      );
      localStorage.setItem("timeTicks", data.timeTicks);
      this.setState({
        forecastGraph: data.forecastGraph.slice(0, 47),
        forecast: data.forecast,
        genMixGraph: data.genMixGraph,
        nowForecast: data.forecast[0].forecast,
        nowIndex: data.forecast[0].index,
        when: data.bestTime[0].when,
        from: moment
          .unix(data.bestTime[0].from)
          .utc()
          .format("HH:mm"),
        to: moment
          .unix(data.bestTime[0].to)
          .utc()
          .format("HH:mm"),
        bestForecast: data.bestTime[0].forecast.toFixed(0),
        timeTicks: data.timeTicks
      });
    });
  };

  render() {
    return (
      <>
        <div className="max-w-4xl m-auto mb-0 pb-0">
          <div className="flex flex-row items-center justify-between border-b border-gray-800">
            <div className="py-6 pr-12 flex flex-row items-center">
              <div className="w-4 h-4 bg-white rounded-full mr-2" />
              <h1 className="text-white text-sm font-inter-semibold uppercase">
                Shift
              </h1>
            </div>
            <a
              href="#about"
              className="text-gray-300 uppercase text-xs font-inter-semibold"
            >
              Info
            </a>
          </div>

          <div>
            {/* <Alert level={this.state.nowIndex} /> */}
            <div className="mt-16 text-white text-sm font-inter-semibold uppercase">
              <p>In the next 24 hours</p>
            </div>
            <h2 className="mt-4 w-full text-5xl inline-block text-white leading-tight font-inter-reg">
              The best time to consume <br />
              electricity in
              <span className="ml-4 inline-flex">
                <div className="relative">
                  <button
                    onClick={this.handleToggleClick}
                    className="flex border-b-2 pr-2 border-white border-dashed flex-row items-center"
                  >
                    {/* <div className="w-4 h-4 bg-white rounded-full mr-2" /> */}
                    <h1 className="text-white text-5xl font-inter-reg">
                      {this.state.selectedRegionName}
                    </h1>
                    <span className="ml-3 mt-1 text-sm font-inter-med">
                      &#x25BC;
                    </span>
                  </button>
                  <div
                    className={`${
                      this.state.showRegionSelect ? "block" : "hidden"
                    } absolute top-0 mt-20 rounded z-50 flex flex-col border-t border-l border-r border-gray-800 bg-brand-dark`}
                  >
                    {this.state.regions.map(number => (
                      <button
                        onClick={e =>
                          this.handleClick(e, number.id, number.name)
                        }
                        className="flex flex-row items-center whitespace-no-wrap px-5 py-4 border-b border-gray-800 text-2xl font-inter-reg text-white"
                      >
                        {number.id === this.state.selectedRegionId ? (
                          <>
                            {/* <CheckIconSmall className="w-5 h-5 mr-4" />{" "}*/}
                            <p className="text-gray-600">{number.name}</p>
                          </>
                        ) : (
                          <>
                            {" "}
                            {/* <div className="w-4 h-4 border border-gray-600 rounded-full mr-4" />{" "} */}
                            <p>{number.name}</p>
                          </>
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </span>{" "}
              is:
            </h2>
            <div className="mt-6 flex flex-row border-t border-gray-900">
              <div className="flex flex-col justify-end mr-4">
                <p className="mt-6 ml-1 text-md uppercase text-white font-inter-semibold ">
                  {this.state.when}
                </p>
                <h2 className="text-7xl text-white font-inter-reg t-num tracking-tighter leading-tight">
                  {this.state.from}
                </h2>
              </div>
              <div className="flex flex-col justify-end pb-4 mr-4">
                <h2 className="text-6xl text-white font-inter-reg leading-tight">
                  ⟶
                </h2>
              </div>
              <div className="flex flex-col justify-end">
                <p className="mt-6 text-md uppercase text-white font-inter-semibold " />
                <h2 className="text-7xl text-white font-inter-reg t-num tracking-tighter leading-tight">
                  {this.state.to}
                </h2>
              </div>
            </div>
            <div className="mt-4 pb-0 mb-0 flex flex-row border-t border-gray-900">
              <div className="flex flex-col w-2/5 mr-4 pb-2 justify-start border-r border-gray-900">
                <p className="mt-8 text-sm uppercase text-white font-inter-semibold ">
                  Average Emissions during this period
                </p>
                <h2 className="mt-3 text-5xl text-white font-inter-reg leading-tight">
                  {this.state.bestForecast}
                  <span className="text-3xl">gCO₂/kWh</span>
                </h2>
                <p className="w-3/4 mt-3 text-sm text-white font-inter-reg ">
                  For every kilowatt hour you consume during this time,{" "}
                  {this.state.bestForecast}g of CO₂ will be emitted.
                </p>
              </div>
              <div className="flex flex-col pl-6 flex-1 justify-start pl-3">
                <p className="mt-8 pb-6 text-sm uppercase text-white font-inter-semibold ">
                  Best appliances to use
                </p>
                <div className="flex flex-row items-start mb-4">
                  <CheckIcon className="mr-3" />{" "}
                  <p className="text-white font-inter-reg text-lg">
                    Washing Machine
                  </p>
                </div>
                <div className="flex flex-row items-start mb-4">
                  <CheckIcon className="mr-3" />{" "}
                  <p className="text-white font-inter-reg text-lg">
                    Tumble Dryer
                  </p>
                </div>
                <div className="flex flex-row items-start mb-24">
                  <CheckIcon className="mr-3" />{" "}
                  <p className="text-white font-inter-reg text-lg">
                    Dishwasher
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-white">
          <div className="max-w-4xl m-auto">
            <div className="flex flex-row border-b border-gray-200">
              <div className="flex flex-col w-1/3 mr-4 pb-2 justify-start border-r border-gray-200">
                <p className="pt-12 mb-5 text-sm uppercase text-black font-inter-semibold ">
                  Current Carbon Intensity
                </p>
                <Alert level={this.state.nowIndex} />
                <h2 className="mt-1 text-5xl text-black font-inter-reg leading-tight">
                  {this.state.nowForecast}
                  <span className="text-3xl">gCO₂/kWh</span>
                </h2>
                <p className="w-3/4 mt-3 text-sm text-black font-inter-reg ">
                  For every kilowatt hour you consume now,{" "}
                  {this.state.nowForecast}g of CO₂ will be emitted.
                </p>
              </div>
              <div className="flex flex-col justify-start pl-3">
                <p className="mt-12 mb-6 text-sm uppercase text-black font-inter-semibold ">
                  Current Generation Mix
                </p>
                <VictoryChart
                  containerComponent={
                    <VictoryContainer responsive={false} />
                  }
                  height={340}
                  width={600}
                  padding={{ top: 12, bottom: 100, left: 0, right: 140 }}
                >
                  <VictoryAxis
                    style={{
                      axis: { stroke: "none" },
                      tickLabels: { fill: "none" }
                    }}
                  />
                  <VictoryBar
                    horizontal={true}
                    barRatio={1}
                    cornerRadius={1}
                    labelComponent={
                      <VictoryLabel dx={d => (d.y === 0 ? -10 : 0)} />
                    }
                    labels={d => d.y + "% " + d.x}
                    data={this.state.genMixGraph}
                    style={{
                      data: {
                        fill: d => d.color
                      },
                      labels: {
                        fill: d => (d.y === 0 ? "#A7ADB2" : "#666F79"),
                        fontSize: 14,
                        fontFamily: d =>
                          d.y === 0
                            ? "'InterRegular', sans-serif"
                            : "'InterMedium', sans-serif"
                      }
                    }}
                  />
                  <VictoryLegend
                    x={0}
                    y={268}
                    orientation="horizontal"
                    gutter={20}
                    symbolSpacer={10}
                    padding={0}
                    style={{
                      labels: {
                        fill: "#000000",
                        fontSize: 14,
                        fontFamily: "'InterMedium', sans-serif"
                      }
                    }}
                    data={[
                      {
                        name: "Non-Renewable",
                        symbol: { fill: "#3B4148", type: "square" }
                      },
                      {
                        name: "Renewable",
                        symbol: { fill: "#38B45A", type: "square" }
                      }
                    ]}
                  />
                </VictoryChart>
              </div>
            </div>
          </div>

          <div className="max-w-4xl m-auto">
            <div className="pt-6 text-black text-sm font-inter-semibold uppercase">
              <p>24 hour forecast</p>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col justify-start flex-1 mt-2">
                <VictoryChart
                  domainPadding={{ x: [30, 14] }}
                  height={200}
                  width={600}
                  padding={{ top: 12, bottom: 20, left: 0, right: 0 }}
                  scale={{ x: "time" }}
                >
                  <VictoryAxis
                    tickValues={this.state.timeTicks}
                    tickFormat={t =>
                      `${moment
                        .unix(t)
                        .utc()
                        .format("HH:mm")}`
                    }
                    tickLabelComponent={<VictoryLabel y={0} dy={186} />}
                    style={{
                      axis: { stroke: "#D3D5D8" },
                      tickLabels: {
                        fontSize: 8,
                        fontFamily: "'InterMedium', sans-serif"
                      }
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    tickLabelComponent={
                      <VictoryLabel
                        textAnchor="start"
                        tickValues={[100, 200, 300, 400, 500]}
                        text={t => (t === 500 ? t + "gCO₂/kWh" : t)}
                        x={0}
                        dy={10}
                      />
                    }
                    style={{
                      axis: { stroke: "none" },
                      tickLabels: {
                        fontSize: 8,
                        fontFamily: "'InterMedium', sans-serif"
                      },
                      grid: { stroke: "#D3D5D8" }
                    }}
                  />
                  <VictoryBar
                    labelComponent={
                      <VictoryTooltip
                        cornerRadius={1}
                        activateData={true}
                        pointerLength={2}
                        pointerWidth={6}
                        style={{
                          padding: 8,
                          fill: "white",
                          fontSize: 8,
                          fontFamily: "'InterMedium', sans-serif"
                        }}
                        flyoutStyle={{
                          paddingHorizontal: 8,
                          stroke: "none",
                          fill: "#3B4148"
                        }}
                      />
                    }
                    events={[
                      {
                        target: "data",
                        eventHandlers: {
                          onMouseOver: () => {
                            return [
                              {
                                target: "data",
                                mutation: () => ({
                                  style: {
                                    fill: "#3B4148"
                                  }
                                })
                              },
                              {
                                target: "labels",
                                mutation: () => ({
                                  active: true
                                })
                              }
                            ];
                          },
                          onMouseOut: () => {
                            return [
                              {
                                target: "data",
                                mutation: () => {}
                              },
                              {
                                target: "labels",
                                mutation: () => ({ active: false })
                              }
                            ];
                          }
                        }
                      }
                    ]}
                    data={this.state.forecastGraph}
                    barRatio={0.7}
                    cornerRadius={1}
                    domain={{ y: [0, 500] }}
                    style={{
                      data: {
                        fill: d => (d.isBest ? "#3B4148" : "#D3D5D8")
                      }
                    }}
                  />
                </VictoryChart>
              </div>
            </div>
          </div>
          <div id="about" className="max-w-4xl m-auto mt-32 pb-32">
            <div className="flex flex-row border-t border-gray-200">
              <div className="pt-6 pb-10 pr-8 flex flex-col w-2/3 border-r border-gray-200">
                <h2 className="mb-2 text-gray-600 text-sm font-inter-semibold uppercase">
                  About
                </h2>
                <p className="text-gray-600 text-sm font-inter-reg">
                  Shift highlights the carbon intensity of the UK
                  electricity grid.
                  <p className="mt-2 text-gray-600 text-sm font-inter-reg">
                    It recommends the least intense two hour period, within
                    the next 24 hours, to help you minimise your carbon
                    dioxide (CO₂) emissions on a regional level.
                  </p>
                </p>
                <p className="mt-2 text-gray-600 text-sm font-inter-reg">
                  <a
                    className="underline"
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    href="https://andrewpairman.com/blog/shift"
                  >
                    Read the blog post
                  </a>{" "}
                  &#x2197;
                </p>
              </div>
              <div className="pt-6 pl-6 pb-10 flex flex-col w-1/3 ">
                <h2 className="mb-1 text-gray-600 text-sm font-inter-semibold uppercase">
                  Resources
                </h2>
                <ul className="text-gray-600 text-sm font-inter-reg">
                  <li className="mb-1">
                    <a
                      className="underline"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href="https://en.wikipedia.org/wiki/Emission_intensity"
                    >
                      Carbon Intensity (Wikipedia)
                    </a>{" "}
                    &#x2197;
                  </li>
                  <li className="mb-1">
                    <a
                      className="underline"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href="https://en.wikipedia.org/wiki/Energy_in_the_United_Kingdom"
                    >
                      Energy in the UK (Wikipedia)
                    </a>{" "}
                    &#x2197;
                  </li>
                </ul>
                <h2 className="mt-4 mb-1 text-gray-600 text-sm font-inter-semibold uppercase">
                  Data Source
                </h2>
                <ul className="text-gray-600 text-sm font-inter-reg">
                  <li className="">
                    <a
                      className="underline"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href="https://carbonintensity.org.uk"
                    >
                      Carbon Intensity API
                    </a>{" "}
                    &#x2197;
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/*  <ul>
          {this.state.forecast.map(number => (
            <li className="font-inter-reg text-white">
              <p>{number.forecast}</p>
              <p>{number.index}</p>
              <p>{number.from}</p>
            </li>
          ))}
        </ul> */}
      </>
    );
  }
}

export default App;
